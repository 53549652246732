<template>
  <div>
    <div class="ribbons-wrapper" style="margin-top: 20px">
      <div
        :class="
          checkRecommend && subcribeData.package_recommended === '1'
            ? 'ribbon'
            : 'subscription-card'
        "
        :style="{
          backgroundColor: checkRecommend ? '#ffffff' : '#faf8f8',
        }"
      >
        <span class="ribbon1">
          <span>
            <h5>Recommended</h5>
          </span>
        </span>
        <div class="row flex ribbon-desc">
          <div>
            <div
              class="card-title"
              style="color: #4f4f4f; font-size: 22px; font-weight: 500"
            >
              {{ subcribeData.package_name ?? "Package Name" }}
            </div>
            <p class="card-text">
              Start with confidence with a lower rate up to
              <b>{{ subcribeData.package_trx_amount ?? "0" }}</b> transactions
              per year including payment methods: FPX & DuitNow
            </p>
            <div class="d-flex align-items-center gap-3 my-4">
              <img
                src="@/assets/img/fpx-logo.png"
                alt=""
                style="height: 25px"
                class="mr-4 mb-md-0"
              />
              <img
                src="@/assets/img/duit_now.webp"
                alt=""
                style="height: 45px"
                class="mr-4 mb-md-0"
              />
            </div>
            <p style="margin-bottom: 0px !important; color: #4f4f4f">
              <span style="font-size: 27px; font-weight: 500"
                >RM
                {{
                  subcribeData.package_price
                    ? Math.floor(subcribeData.package_price)
                    : "0"
                }}</span
              >
              / Per
              <span style="text-transform: capitalize">{{
                subcribeData.package_duration_type ?? "Month"
              }}</span>
            </p>
            <p style="font-size: 15px !important">
              <i>Additional Registration Fees: RM 250 (One-time cost)</i>
            </p>
            <button
              class="btn btn-tekkis"
              @click="
                isModalVisible = true;
                isFormVisible = true;
              "
            >
              Subscribe Now
            </button>
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal fade"
      :class="{ show: isModalVisible }"
      id="staticBackdrop"
      tabindex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
      v-if="isModalVisible"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <!-- <div class="modal-header">
            <h6 style="color: #12070b">Enter your detail</h6>
            <button
              type="button"
              class="close"
              @click="closeModal"
              aria-label="Close"
              style="color: #12070b !important"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div> -->
          <div class="modal-body m-2" v-if="isFormVisible">
            <div
              class="d-flex justify-content-between align-items-center flex-fill"
            >
              <span style="color: #12070b; font-size: 22px; font-weight: 500"
                >Enter your detail</span
              >
              <a
                @click="closeModal"
                style="
                  color: #12070b !important;
                  font-size: 22px;
                  cursor: pointer;
                "
              >
                <span aria-hidden="true">&times;</span>
              </a>
            </div>
            <div
              class="d-flex justify-content-start mt-2 text-left"
              style="font-size: 16px; font-weight: 400; color: #4f4f4f"
            >
              After your payment is successful, our customer experience team
              will contact you regarding your company's supporting documents
            </div>
            <div class="flex flex-col justify-content-start text-left mt-4">
              <div class="form-group">
                <label for="name" style="font-weight: 500"
                  >Full Name* (NRIC)</label
                >
                <input
                  type="text"
                  class="form-control"
                  id="name"
                  placeholder="Full Name based on Identification card"
                  v-model="name"
                  @input="
                    name ? (nameError = '') : (nameError = 'Name is required')
                  "
                />
                <div style="color: red" v-if="nameError" class="pt-2 ml-1">
                  {{ nameError }}
                </div>
              </div>
              <div class="form-group">
                <label for="name" style="font-weight: 500"
                  >Email Address*</label
                >
                <input
                  type="email"
                  class="form-control"
                  placeholder="Email Address*"
                  v-model="email"
                  @input="validateEmail"
                />
                <div v-if="emailError" style="color: red" class="pt-2 ml-1">
                  {{ emailError }}
                </div>
              </div>
              <div class="form-group">
                <label for="name" style="font-weight: 500"
                  >Phone Number (for WhatsApp)</label
                >
                <input
                  type="text"
                  class="form-control"
                  placeholder="Phone Number"
                  v-model="phoneNumber"
                  @input="validatePhoneNumber"
                />
                <div
                  v-if="phoneNumberError"
                  style="color: red"
                  class="pt-2 ml-1"
                >
                  {{ phoneNumberError }}
                </div>
              </div>
              <div>
                <button
                  class="btn btn-tekkis"
                  style="width: 100%"
                  @click="submitForm"
                >
                  Pay Now
                </button>
              </div>
            </div>
          </div>
          <div class="modal-body m-2" v-else>
            <div
              class="d-flex justify-content-between align-items-center flex-fill"
            >
              <span
                style="color: #12070b; font-size: 22px; font-weight: 500"
              ></span>
              <a
                @click="closeModal"
                style="
                  color: #12070b !important;
                  font-size: 22px;
                  cursor: pointer;
                "
              >
                <span aria-hidden="true">&times;</span>
              </a>
            </div>
            <div
              class="d-flex flex-column justify-content-start mt-2 text-center w-100"
              style="font-size: 16px; font-weight: 400; color: #4f4f4f"
            >
              <div
                style="color: #12070b; font-size: 22px; font-weight: 500"
                class="mb-4"
              >
                Existing Account
              </div>
              <div style="color: #12070b; font-size: 16px" class="px-3 mb-4">
                We found a t-Auth account linked to this email address. Log in
                to t-Auth to manage your SaaS package subscription.
              </div>
              <button class="btn btn-tekkis" @click="goToTauth()">
                Go To t-Auth
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Backdrop -->
    <div class="modal-backdrop fade show" v-if="isModalVisible"></div>
  </div>
</template>

<script>
// import axios from "axios";
export default {
  name: "PackageSubcribeCard",
  props: {
    subcribeData: {
      type: Object,
      required: true,
      default: () => {},
    },
    checkRecommend: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      isModalVisible: false,
      isFormVisible: false,
      name: "",
      nameError: "",
      phoneNumber: "",
      phoneNumberError: "",
      email: "",
      emailError: "",
    };
  },
  mounted() {
    console.log(this.subcribeData);
  },
  methods: {
    async submitForm() {
      if (!this.name || !this.phoneNumber || !this.email) {
        this.nameError = this.name ? "" : "Name is required";
        this.phoneNumberError = this.phoneNumber
          ? ""
          : "Phone number is required";
        this.emailError = this.email ? "" : "Email is required";
        return;
      }
      try {
        const response = await fetch(
          "https://tauth-api.tekkis.com.my/saas_package/saasRegistration",
          {
            method: "POST",
            body: JSON.stringify({
              paymentName: this.name,
              paymentEmail: this.email,
              phoneNumber: this.phoneNumber,
              packageUniqueId: this.subcribeData.package_unique_id,
            }),
          },
          {
            headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
            },
          }
        );

        if (response.ok) {
          const data = await response.json();
          const url = data.response.payment_link;
          this.isModalVisible = false;
          this.name = "";
          this.phoneNumber = "";
          this.email = "";
          window.open(url, "_blank");
        } else {
          this.isFormVisible = false;
          this.isModalVisible = true;
          this.name = "";
          this.phoneNumber = "";
          this.email = "";
        }
        // this.getSelectionSubcribe();
      } catch (error) {
        console.error("Error:", error);
      }
    },
    validatePhoneNumber() {
      if (!this.phoneNumber) {
        this.phoneNumberError = "Phone number is required";
      } else {
        this.phoneNumberError = "";
      }
      this.phoneNumber = this.phoneNumber
        .replace(/[^0-9]/g, "")
        .substring(0, 11);
    },
    validateEmail() {
      this.emailError = "";
      if (!this.email) {
        this.emailError = "Email is required";
      } else if (!this.email.includes("@")) {
        this.emailError = "Invalid email";
      }
    },
    closeModal() {
      this.isModalVisible = false;
    },
    goToTauth() {
      window.location.href = "https://tauth-staging.tekkis.my/";
    },
  },
};
</script>
<style>
.modal {
  display: block; /* Ensure modal is visible when shown */
}
.modal-backdrop {
  display: block; /* Show backdrop when modal is visible */
}
.custom-pl-6 {
  padding-left: 6rem;
  padding-top: 2rem;
}
@media screen and (max-width: 768px) {
  .custom-pl-6 {
    padding: 2rem;
  }
}
.btn-tekkis {
  border-radius: 10px;
  width: 100%;
}
.btn-tekkis:hover {
  background-color: #2d23a8;
  color: #fff;
  cursor: pointer;
}
.subscription-card {
  max-width: 410px;
  height: 100%;
  border-radius: 10px;
  /* box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1); */
  border: 1px solid #dfdfdf; /* Added border */
  text-align: left;
}
.ribbon {
  position: relative;
  max-width: 410px;
  border-radius: 10px;
  /* box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1); */
  border: 1px solid #dfdfdf; /* Added border */
  text-align: left;
}

.ribbon::before {
  content: "";
  position: absolute;
  width: 100%;
  opacity: 30%;
  z-index: 0;
}

.ribbon1 {
  position: absolute;
  top: -14px;
  left: 200px;
}
.ribbon1:after {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  border-left: 0px solid transparent;
  border-right: 0px solid transparent;
  border-top: 0px solid #f8463f;
}
.ribbon1 span {
  position: relative;
  display: block;
  text-align: center;
  background: #1a4592;
  font-size: 27px;
  line-height: 1;
  padding: 12px 8px 10px;
  border-radius: 10px;
  width: 100%;
}

.ribbon1 h5 {
  background: linear-gradient(
    250deg,
    rgba(237, 250, 254, 1) 0%,
    rgba(125, 197, 253, 1) 100%
  );
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  margin: 0px 20px;
  font-weight: 400;
  font-size: 18px !important;
}

.ribbon1 span:before,
.ribbon1 span:after {
  position: absolute;
  content: "";
}
.ribbon1 span:before {
  height: 16px;
  width: 110%;
  left: -8px;
  z-index: -1;
  top: 0;
  border-radius: 8px 8px 0 0;
  background: #090650;
}
.ribbon1 span:after {
  height: 16px;
  width: 100%;
  left: 0px;
  top: 0;
  background: #1a4592;
}
.ribbon-desc {
  padding-right: 2rem;
  padding-left: 2rem;
  padding-top: 2rem;
  padding-bottom: 1rem;
}
.form-group label {
  font-size: 16px !important;
  color: #4f4f4f !important;
}
.card-text {
  color: #4f4f4f !important;
}
</style>
