<template>
  <div id="pricing-tpay-price-subcribe">
    <div
      class="pricing-div mx-1250 px-2 px-md-0 text-start text-md-center pb-5"
    >
      <h3 class="mb-4">Pricing Plan Calculator</h3>
      <p style="font-size: 1.25rem; margin-bottom: 21px; color: #4f4f4f">
        Ensure your payment solution scales efficiently along with your business
        growth and reduces processing costs
      </p>
      <div class="d-flex align-items-center justify-content-center gap-3">
        <span class="mr-2" style="color: #2d23a8">Yearly</span>
        <label class="custom-toggle mt-2">
          <input
            type="checkbox"
            class="toggle-input"
            v-model="isYearly"
            disabled
          />
          <span class="toggle-slider"></span>
        </label>
      </div>
      <div
        class="d-flex flex-column flex-md-row align-items-center align-items-md-start justify-content-center gap-3 mt-4"
      >
        <div class="flex-fill custom-pl-6">
          <div class="form-group position-relative">
            <div
              class="range-value mb-2 position-absolute"
              style="
                bottom: 40px;
                border-radius: 5px;
                padding: 5px;
                background-color: #fff;
                color: #000;
                font-size: 14px;
                border: 1px solid #e0e6ed;
              "
              :style="{ left: `${(rangeValue / 2000) * 100}%` }"
            >
              {{ rangeValue }}
            </div>
            <input
              type="range"
              class="form-control-range"
              id="formControlRange"
              v-model="rangeValue"
              min="0"
              max="2000"
              @input="getSelectionSubcribe"
              style="accent-color: #4361ee"
            />
            <div class="d-flex justify-content-between" style="font-size: 14px">
              <span>0</span>
              <span>> 2000</span>
            </div>
          </div>
          <div class="d-flex justify-content-between mt-4">
            <div class="text-color">Estimated annual transaction volume</div>
            <div>
              <input
                type="text"
                class="form-control"
                v-model="rangeValue"
                @input="validateNumber"
              />
            </div>
          </div>
        </div>
        <div class="flex-fill d-flex justify-content-center">
          <packageSubcribeCard
            :subcribeData="selectionSubcribe"
            v-if="Object.keys(selectionSubcribe).length"
          />
        </div>
      </div>
    </div>
    <div class="subscription-details-content">
      <div class="mx-1250 px-2 px-md-0 text-start text-md-center py-5">
        <h3>Payment Solutions for Every Business</h3>
        <p style="color: #4f4f4f; font-size: 20px" class="px-5">
          Manage your transactions smoothly with our payment solution plans.
          Each package is designed to accommodate businesses with varying
          transaction volumes, ensuring you get the best value for your needs.
        </p>
        <div class="d-flex flex-wrap justify-content-center gap-3">
          <div
            v-for="data in subscribeData"
            :key="data.package_id"
            class="my-2 mx-3"
          >
            <packageSubcribeCard :checkRecommend="true" :subcribeData="data" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import packageSubcribeCard from "./PackageSubcribeCard.vue";
export default {
  name: "PricingTpaySubcribe",
  components: {
    packageSubcribeCard,
  },
  data() {
    return {
      isYearly: false, // Default value
      rangeValue: 500, // Default value
      subscribeData: [],
      selectionSubcribe: {},
    };
  },
  mounted() {
    this.getDataSubscribe();
  },
  methods: {
    validateNumber($event) {
      const value = $event.target.value.replace(/\D/g, "");
      this.rangeValue = value ? parseInt(value, 10) : 0;
      if (this.rangeValue < 0) {
        this.rangeValue = 0;
      } else if (this.rangeValue > 2000) {
        this.rangeValue = 2000;
      }
      this.getSelectionSubcribe();
    },
    async getDataSubscribe() {
      try {
        const response = await axios.post(
          "https://tauth-api.tekkis.com.my/saas_package/getSaasPackage"
        );
        this.subscribeData = response.data.response.packageList;
        this.getSelectionSubcribe();
      } catch (error) {
        console.error("Error:", error);
      }
    },
    getSelectionSubcribe() {
      var rangeValue = 0;
      if (this.rangeValue <= 500) {
        rangeValue = 500;
      } else {
        rangeValue = 2000;
      }

      this.selectionSubcribe = this.subscribeData.find(
        (data) =>
          data.package_trx_amount <= rangeValue &&
          data.package_trx_amount >= rangeValue
      );
      console.log(this.selectionSubcribe);
    },
  },
};
</script>

<style scoped>
.custom-toggle {
  position: relative;
  display: inline-block;
  width: 42px; /* Adjusted width */
  height: 26px; /* Adjusted height */
}

.toggle-text {
  position: absolute;
  left: 0;
  top: 0;
  margin-right: 40px; /* Adjusted margin */
  font-size: 18px;
  line-height: 1;
  vertical-align: middle;
}

.toggle-input {
  opacity: 0;
  width: 0;
  height: 0;
}

.toggle-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  transition: 0.4s;
  border-radius: 24px; /* Adjusted border-radius */
  border: 1px solid #e0e6ed;
}

.toggle-slider:before {
  position: absolute;
  content: "";
  height: 18px; /* Adjusted height */
  width: 18px; /* Adjusted width */
  left: 3px; /* Adjusted left */
  bottom: 3px; /* Adjusted bottom */
  background-color: #2d23a8;
  transition: 0.4s;
  border-radius: 50%;
}

.toggle-input:checked + .toggle-slider {
  background-color: #2196f3;
}

.toggle-input:checked + .toggle-slider:before {
  transform: translateX(16px); /* Adjusted transform */
}

.subscription-card {
  max-width: 410px;
  height: 100%;
  background-color: #faf8f8;
  border-radius: 10px;
  /* box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1); */
  border: 1px solid #dfdfdf; /* Added border */
  text-align: left;
}
.subscription-details-content {
  background: linear-gradient(180deg, #faf8f8 0%, #fdf3ed 100%);
}
.text-color {
  color: #4f4f4f;
}
</style>
