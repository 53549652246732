<template>
  <div class="pricingtpay" id="pricing-pg">
    <PricingTpayDashboard />
    <PricingTpaySubcribe />
    <PricingTpayPrice id="pricing-tpay-price" />
    <!-- <PricingTpayPriceV4 id="pricing-tpay-price" /> -->
    <!-- <PricingTpayPrice2 id="pricing-tpay-price" /> -->
    <PricingTpayProcess />
    <PricingTpayRegister />
    <PricingTpayFeatures />
    <PricingTpayCalculator />
    <PricingTpaySupport />
    <GetStarted />
  </div>
</template>

<script>
import PricingTpayDashboard from "../components/PricingTpayComponents/PricingTpayDashboard.vue";
import PricingTpayPrice from "../components/PricingTpayComponents/PricingTpayPrice.vue";
// import PricingTpayPrice2 from "../components/PricingTpayComponents/PricingTpayPrice2.vue";
//import PricingTpayPriceV4 from "../components/PricingTpayComponents/PricingTpayPriceV4.vue";
import PricingTpaySubcribe from "../components/PricingTpayComponents/PricingTpaySubcribe.vue";
import PricingTpayProcess from "../components/PricingTpayComponents/PricingTpayProcess.vue";
import PricingTpayRegister from "../components/PricingTpayComponents/PricingTpayRegister.vue";
import PricingTpayFeatures from "../components/PricingTpayComponents/PricingTpayFeatures.vue";
import PricingTpayCalculator from "../components/PricingTpayComponents/PricingTpayCalculator.vue";
import PricingTpaySupport from "../components/PricingTpayComponents/PricingTpaySupport.vue";
import GetStarted from "../components/GetStarted";

export default {
  name: "PricingTpay",
  components: {
    PricingTpayDashboard,
    PricingTpayPrice,
    // PricingTpayPrice2,
    //PricingTpayPriceV4,
    PricingTpayProcess,
    PricingTpayRegister,
    PricingTpayFeatures,
    PricingTpayCalculator,
    PricingTpaySupport,
    PricingTpaySubcribe,
    GetStarted,
  },
  data() {
    return {
      title: "Tekkis Payment Solutions - Pricing",
      description:
        "No subscription, or hidden fees. Only pay when you make a sale. We now offer ShopeePay and Auto Recurring Payment! Custom solutions and volume pricing available. Get started today!",
    };
  },
  metaInfo() {
    return {
      title: this.title,
      meta: [
        {
          name: "description",
          content: this.description,
        },
        {
          property: "og:title",
          content: this.title,
        },
        { property: "og:site_name", content: "Tekkis" },
        { property: "og:type", content: "website" },
      ],
    };
  },

  methods: {
    updateMetadata() {
      // Update the data properties to change the metadata
      this.title = "description";
      this.description =
        "No subscription, or hidden fees. Only pay when you make a sale. We now offer ShopeePay and Auto Recurring Payment! Custom solutions and volume pricing available. Get started today!";
    },
  },
};
</script>

<style lang="scss" scoped>
// #pricing-pg {
//   max-width: 1920px;
//   margin: 0 auto;
// }
</style>
